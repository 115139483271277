import React, {useEffect} from 'react';
import "./HowtoPlay.css";
import closemodal from"./closemodal.svg";

const HowtoPlay = props => {
    // const closeOnEscapeKeyDown = (e) => {
    //     if (!props.show){
    //         return null
    //     }
    //     if(e.charCode===13){
    //             props.onClose();
    //             console.log('escape keydown')
    //         }
    //     }
    // useEffect(()=>{
    //     document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    //     return function cleanup(){
    //         document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    //     }
    // }, [props.show])

    const closeModal=(event)=> {
        if (event.keyCode === 13 || 27) {
            console.log('keypressevent')
            props.onClose();
        }
    }
    
    if (!props.show){
        return null
    }
    return (
        <div className ={props.className} onKeyUp={e=>closeModal(e)} onClick ={props.onClose} >
            <div className ="play-content" id={props.modalid} onClick={e => e.stopPropagation()}>
            <div className="closemodalplay"  onClick ={props.onClose}> 
               <img className="closemodalplay-img"src={closemodal}></img>
               </div>
                <div className = "play-header">
                    <h4 className = "play-title">
                        {props.title}
                    </h4>
                </div>
                <div className="play-body">
                    {props.children}
                </div>
                <div className="play-footer">
                    <button autoFocus onClick = {() => {props.onClose()}} className= {props.btnclass} > {props.btntext}</button>
                </div>
            </div>
        </div>
    )
}

export default HowtoPlay