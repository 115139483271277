import React, {useEffect} from 'react';
import "./GameOver.css"
import closemodal from"./closemodal.svg"

const GameOver = props => {
    const closeOnEscapeKeyDown = (e) => {
        // console.log('outside props show')
        console.log({props})
        if (!props.show){
            return null;
        }

        // console.log('props not show', e);
        if((e.charCode===27 || e.keyCode===27) ||(e.charCode===13 || e.keyCode ===13)){
            console.log('running close on escape key down', e.charCode, e.keyCode)
                props.onClose();    
        }
        }
    useEffect(()=>{
        // console.log('running useeffect to add event listener')
        document.body.addEventListener('keydown', closeOnEscapeKeyDown);
        return function cleanup(){
            document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
        }
    }, [props.show])

    if (!props.show){
        return null
    }
    
    return (
        <div className ={props.className}  onClick ={props.onClose} >
            <div className ="gameOver-content" onClick={e => e.stopPropagation()}>
            <div className="closemodalgame" onClick = {() => {props.onClose()}}> 
               <img className="closemodalgame-img"src={closemodal}></img>
               </div>
                <div className = "gameOver-header">
                    <h4 className = "gameOver-title">
                        {props.title}
                    </h4>
                </div>
                <div className="gameOver-body">
                    {props.children}
                </div>
                <button autoFocus onClick = {() => {props.onClose()}}  className= {props.btnclass} > {props.btntext}</button>
            </div>
        </div>
    )
}
export default GameOver