import React, {useEffect} from 'react';
import "./Leaderboard.css"
import closemodal from"./closemodal.svg"

const Leaderboard = props => {
    
    const closeOnEscapeKeyDown = (e) => {
        if (!props.show){
            return null
        }
        if((e.charCode===27 || e.keyCode===27) ||(e.charCode===13 || e.keyCode ===13)){
                props.onClose();
            }
        }
    useEffect(()=>{
        document.body.addEventListener('keydown', closeOnEscapeKeyDown);
        return function cleanup(){
            document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
        }
    }, [props.show])
    if (!props.show){
        return null
    }
    return (
        <div className ="leaderboard" onClick ={props.onClose}>
            <div className ="leaderboard-content" id={props.modalid} onClick={e => e.stopPropagation()}>
            <div className="closemodal-leaderboard" onClick = {() => {props.onClose()}}> 
               <img className="closemodal-leaderboard-img"src={closemodal}></img>
               </div>
            <div className = "leaderboard-header">
                    <h4 className = "leaderboard-title">
                        {props.title}
                        <div className="title-img"></div>
                    </h4>
                </div>
                <div className="leaderboard-body">
                    {props.children}
                </div>
                <div className="leaderboard-footer">
                    <button autoFocus onClick = {() => {props.onClose()}} className= {props.btnclass}>{props.btntext}</button>
                </div>
            </div>

        </div>
    )
}

export default Leaderboard

