import React, {useState, useEffect, useRef} from 'react'
import './jamble.scss'
import axios from 'axios';
import Modal from "./Modal";
import Leaderboard from "./Leaderboard";
import GameOver from "./GameOver";
import HowtoPlay from "./HowtoPlay";
import Round from "./Round";
import JSConfetti from 'js-confetti'



const deck1 = [ "A", "E", "I", "O", "U", 
"A", "E", "I", "O", "U", 
"A", "E", "I", "O", "U", 
"A", "E", "I", "O", "U", 
"A", "E", "I", "O", "U", 
"A", "E"
]

const deck2 = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "A", "B", "C", "D", "E", "F", "G", "H", "I", "L", "M", "N", "O", "P", "R", "S", "T", "U", "V", "W", "Y", "?", "?"
]

const deck3 = [
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "L", "M", "N", "O", "P", "R", "S", "T", "U", "Y", "A", "B", "C", "D", "E", "F", "G", "H", "I", "L", "M", "N", "O", "P", "R", "S", "T", "U", "Y", "?", "?"
]

const deck4 = [
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "L", "M", "N", "O", "P", "R", "S", "T", "Y", "A", "B", "C", "D", "E", "F", "G", "H", "I", "L", "M", "N", "O", "P", "R", "S", "T","Y", "?", "?"
]

const deck5 = [
  "A", "B", "C", "D", "E", "F", "G", "H", "I", "L", "M", "N", "O", "P", "R", "S", "T", "Y", "A", "B", "C", "D", "E", "F", "G", "H", "I", "L", "M", "N", "O", "P", "R", "S", "T","Y", "?", "?"
]

const scoringsystem = 
{
  "Z": 10,
  "Q": 10,
  "J": 9,
  "X": 9,
  "K": 7,
  "V": 6,
  "B": 5,
  "Y": 2,
  "W": 6,
  "G": 3,
  "P": 4,
  "F": 4,
  "M": 4,
  "U": 4,
  "C": 3,
  "D": 2,
  "L": 2,
  "H": 2,
  "R": 1,
  "S": 1,
  "N": 1,
  "I": 1,
  "O": 1,
  "A": 1,
  "T": 1,
  "E": 1, 
  "?": 0
}

 
 

const Jamble = () => {

  const[isPlayOn, setIsPlayOn] = useState(false);
  const [boardNumber, setBoardNumber] = useState(0);
  const [roundNumber, setRoundNumber] = useState(1);

  const [letter1, setletter1] = useState(""); 
  const [letter2, setletter2] = useState(""); 
  const [letter3, setletter3] = useState(""); 
  const [letter4, setletter4] = useState("");
  const [letter5, setletter5] = useState("");

  const [inputValue, setInputValue] = useState("");

  const [scoreletter1, setscoreletter1] = useState(0); 
  const [scoreletter2, setscoreletter2] = useState(0); 
  const [scoreletter3, setscoreletter3] = useState(0); 
  const [scoreletter4, setscoreletter4] = useState(0);
  const [scoreletter5, setscoreletter5] = useState(0);

  const [boardscore, setboardscore] = useState(0);
  const handleInputChange = (event) => {
    setInputValue(event.target.value.toUpperCase());
  }
  const [gamescore, setgamescore] = useState(0);
  const [playername, setplayername] = useState("");
  const [playerrank, setplayerrank] = useState(null);

  var calculateScore = function (myKey) {
  return scoringsystem[myKey];
  };

  const [howtoplay, setHowtoPlay] = useState(false);
  const [round, setRound]=useState(false);
  const [show, setShow] = useState(false);
  const [wrongans, setWrongAns] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [leaderboardModal, setLeaderboardModal] = useState(false);

  const [FirstPlayerRecord, setFirstPlayerRecord] =useState([]);
  const [SecondPlayerRecord, setSecondPlayerRecord] =useState([]);
  const [ThirdPlayerRecord, setThirdPlayerRecord] =useState([]);
  const [FourthPlayerRecord, setFourthPlayerRecord] =useState([]);
 
  const [FirstPlayerName, setFirstPlayerName] =useState([]);
  const [SecondPlayerName, setSecondPlayerName] =useState([]);
  const [ThirdPlayerName, setThirdPlayerName] =useState([]);
  const [FourthPlayerName, setFourthPlayerName] =useState([]);
 

  const handleStartGame = () =>{
      setBoardNumber(1);
      setRoundNumber(1);
    setgamescore(0);
    setIsPlayOn(true); 
    setInputValue("");
    setHowtoPlay(true);
    const letter1= RandomLetterPicker(deck1).toUpperCase();
    setletter1(letter1);
    const letter2= RandomLetterPicker(deck2).toUpperCase();
    setletter2(letter2);
    const letter3= RandomLetterPicker(deck3).toUpperCase();
    setletter3(letter3);
    const scoreletter1 = calculateScore(letter1);
    const scoreletter2 = calculateScore(letter2);
    const scoreletter3 = calculateScore (letter3); 
    setscoreletter1(scoreletter1);
    setscoreletter2(scoreletter2);
    setscoreletter3(scoreletter3);
    const boardscore = scoreletter1+ scoreletter2 + scoreletter3; 
    setboardscore(boardscore); 
    }
  const handleStartBoard = () =>{
    if (boardNumber <=6){
      setIsPlayOn(true);
      setInputValue("");
      const letter1= RandomLetterPicker(deck1).toUpperCase();
      setletter1(letter1);
      const letter2= RandomLetterPicker(deck2).toUpperCase();
      setletter2(letter2);
      const letter3= RandomLetterPicker(deck3).toUpperCase();
      setletter3(letter3);
      const scoreletter1 = calculateScore(letter1);
      const scoreletter2 = calculateScore(letter2);
      const scoreletter3 = calculateScore (letter3); 
      setscoreletter1(scoreletter1);
      setscoreletter2(scoreletter2);
      setscoreletter3(scoreletter3);
      const boardscore = scoreletter1+ scoreletter2 + scoreletter3; 
      setboardscore(boardscore);
      setBoardNumber(BoardIncrement(boardNumber));
      setRoundNumber(RoundIncrement(boardNumber, roundNumber));
      console.log({boardNumber, roundNumber});
    } 
    else if (boardNumber===7){
      setRound(true);
      setIsPlayOn(true);
      setInputValue("");
      const letter1= RandomLetterPicker(deck1).toUpperCase();
      setletter1(letter1);
      const letter2= RandomLetterPicker(deck2).toUpperCase();
      setletter2(letter2);
      const letter3= RandomLetterPicker(deck3).toUpperCase();
      setletter3(letter3);
      const letter4 = RandomLetterPicker(deck4).toUpperCase();
      setletter4(letter4);
      const scoreletter1 = calculateScore(letter1)*2;
      const scoreletter2 = calculateScore(letter2)*2;
      const scoreletter3 = calculateScore (letter3)*2; 
      const scoreletter4 = calculateScore (letter4)*2; 
      setscoreletter1(scoreletter1);
      setscoreletter2(scoreletter2);
      setscoreletter3(scoreletter3);
      setscoreletter4(scoreletter4);
      const boardscore = scoreletter1+ scoreletter2 + scoreletter3 +scoreletter4; 
      setboardscore(boardscore);
      setBoardNumber(BoardIncrement(boardNumber));
      setRoundNumber(RoundIncrement(boardNumber, roundNumber));
      console.log({boardNumber, roundNumber});
    }
    else if (boardNumber>7 && boardNumber <=12){
      setIsPlayOn(true);
      setInputValue("");
      const letter1= RandomLetterPicker(deck1).toUpperCase();
      setletter1(letter1);
      const letter2= RandomLetterPicker(deck2).toUpperCase();
      setletter2(letter2);
      const letter3= RandomLetterPicker(deck3).toUpperCase();
      setletter3(letter3);
      const letter4 = RandomLetterPicker(deck4).toUpperCase();
      setletter4(letter4);
      const scoreletter1 = calculateScore(letter1)*2;
      const scoreletter2 = calculateScore(letter2)*2;
      const scoreletter3 = calculateScore (letter3)*2; 
      const scoreletter4 = calculateScore (letter4)*2; 
      setscoreletter1(scoreletter1);
      setscoreletter2(scoreletter2);
      setscoreletter3(scoreletter3);
      setscoreletter4(scoreletter4);
      const boardscore = scoreletter1+ scoreletter2 + scoreletter3 +scoreletter4; 
      setboardscore(boardscore);
      setBoardNumber(BoardIncrement(boardNumber));
      setRoundNumber(RoundIncrement(boardNumber, roundNumber));
      console.log({boardNumber, roundNumber});
    }
    else if (boardNumber ===13){
      setRound(true);
      setIsPlayOn(true);
      setInputValue("");
      const letter1= RandomLetterPicker(deck1).toUpperCase();
      setletter1(letter1);
      const letter2= RandomLetterPicker(deck2).toUpperCase();
      setletter2(letter2);
      const letter3= RandomLetterPicker(deck3).toUpperCase();
      setletter3(letter3);
      const letter4 = RandomLetterPicker(deck4).toUpperCase();
      setletter4(letter4);
      const letter5 = RandomLetterPicker(deck5).toUpperCase();
      setletter5(letter5);
      const scoreletter1 = calculateScore(letter1)*3;
      const scoreletter2 = calculateScore(letter2)*3;
      const scoreletter3 = calculateScore (letter3)*3; 
      const scoreletter4 = calculateScore (letter4)*3; 
      const scoreletter5 = calculateScore (letter5)*3;
      setscoreletter1(scoreletter1);
      setscoreletter2(scoreletter2);
      setscoreletter3(scoreletter3);
      setscoreletter4(scoreletter4);
      setscoreletter5(scoreletter5);
      const boardscore = scoreletter1+ scoreletter2 + scoreletter3 +scoreletter4 +scoreletter5; 
      setboardscore(boardscore);
      setBoardNumber(BoardIncrement(boardNumber));
      setRoundNumber(RoundIncrement(boardNumber, roundNumber));
      console.log({boardNumber, roundNumber});
    }
    else if (boardNumber>13 && boardNumber<=17){
      setIsPlayOn(true);
      setInputValue("");
      const letter1= RandomLetterPicker(deck1).toUpperCase();
      setletter1(letter1);
      const letter2= RandomLetterPicker(deck2).toUpperCase();
      setletter2(letter2);
      const letter3= RandomLetterPicker(deck3).toUpperCase();
      setletter3(letter3);
      const letter4 = RandomLetterPicker(deck4).toUpperCase();
      setletter4(letter4);
      const letter5 = RandomLetterPicker(deck5).toUpperCase();
      setletter5(letter5);
      const scoreletter1 = calculateScore(letter1)*3;
      const scoreletter2 = calculateScore(letter2)*3;
      const scoreletter3 = calculateScore (letter3)*3; 
      const scoreletter4 = calculateScore (letter4)*3; 
      const scoreletter5 = calculateScore (letter5)*3;
      setscoreletter1(scoreletter1);
      setscoreletter2(scoreletter2);
      setscoreletter3(scoreletter3);
      setscoreletter4(scoreletter4);
      setscoreletter5(scoreletter5);
      const boardscore = scoreletter1+ scoreletter2 + scoreletter3 +scoreletter4 +scoreletter5; 
      setboardscore(boardscore);
      setBoardNumber(BoardIncrement(boardNumber));
      setRoundNumber(RoundIncrement(boardNumber, roundNumber));
      console.log({boardNumber, roundNumber});
    }
    else if (boardNumber>=18){
      console.log(boardNumber);
     setGameOver(true);
     AddConfetti();
    }
  };




function BoardIncrement(number) {
  number++;
 return number;
}

const RoundIncrement = (board_number, round_number)=>{
  if (board_number<=6){
    round_number =1;
  }
  else if (board_number>6 && board_number <=12){
    round_number =2;
  }
  else if (board_number>12 && board_number<=18){
    round_number =3;
  }
  return (round_number);
}

const RandomLetterPicker = (deck) => {
    const randomIndex = Math.floor(Math.random() * deck.length);
    const randomLetter = deck[randomIndex];
    return randomLetter;
  };

const handleKeypressSubmit = e => {
    //it triggers by pressing the enter key
  if (e.keyCode === 13 && inputValue !=='') {
    handleSubmit();
  }
};
const handleGuessAgain = ()=> {
      setWrongAns(false);
  
}

const handleplayername = (event)=>{
  setplayername(event.target.value);
  event.preventDefault();
  console.log(playername);
} 

const keyPressClose = (e)=>{
  if(e.key==="Enter" ){
    console.log('enter pressed')
   e.preventDefault();
  setHowtoPlay(false);
  setRound(true);
  }}



const getLeaderboard = async ()=>{
  console.log('running getLeaderboard')
  setGameOver(false);
  await axios.post('https://api.jamble.ink/leaderboards/jamble', {
    player:playername,
    score: gamescore 
  })
  .then( async ()=>{
   const leaderboards= await axios.get('https://api.jamble.ink/leaderboards/jamble');
   console.log('returning leaderboards');
    return leaderboards;
    //async function, await the promise first. .then -> copy the await and put 
  })
  .then(result=>{
    console.log('returning records');
    console.log(result);
    setFirstPlayerName(result.data[0].player);
    setFirstPlayerRecord(result.data[0].score);
    setSecondPlayerName(result.data[1].player);
    setSecondPlayerRecord(result.data[1].score);
    setThirdPlayerName(result.data[2].player);
    setThirdPlayerRecord(result.data[2].score);
    setFourthPlayerName(result.data[3].player);
    setFourthPlayerRecord(result.data[3].score);
    let player_index = result.data.findIndex(x => x.player === playername);
    console.log(player_index);
    setplayerrank(player_index);
    setLeaderboardModal(true);
   }) 
   .then(result =>{
   setplayername('');
   console.log({playername}) 
   }).catch(error=>{console.log(error)}
   )
}

const AddConfetti =()=>{
  const gameOverClass = document.getElementsByClassName('leaderboard');
const jsConfetti = new JSConfetti({gameOverClass})
jsConfetti.addConfetti({
  emojis: ['🦄', '🐓', '🐔' ,'🐣'],
  emojiSize: 100,
  confettiNumber: 50,
})
}

  const handleSubmit = async ()=>{
    try{

        if(inputValue !== ''){
            const letter1Matches = letter1 === '?' || inputValue.includes(letter1.toUpperCase())
            const letter2Matches = letter2 === '?' || inputValue.includes(letter2.toUpperCase())
            const letter3Matches = letter3 === '?' || inputValue.includes(letter3.toUpperCase())
            const getdictionaryword = await axios.get(
              `https://api.dictionaryapi.dev/api/v2/entries/en/${inputValue}`);
            const isDictionaryWord = inputValue === getdictionaryword.data[0].word.toUpperCase()

            if(letter1Matches && letter2Matches && letter3Matches && isDictionaryWord && (boardNumber < 18))
            {
              setgamescore(gamescore + boardscore);
              setShow(true);
              setInputValue("");
            } else if (letter1Matches && letter2Matches && letter3Matches && isDictionaryWord && (boardNumber === 18))
            {
              setgamescore(gamescore + boardscore);
              setGameOver(true);
              setInputValue("");
              AddConfetti();
            }
            else 
            {
              setWrongAns(true);
              setInputValue("");
            }}
        }
    catch(error){
      setWrongAns(true);
      setInputValue("");

    }
 }
 
  return (
    <div className='jamble'> 
        {
          isPlayOn && (roundNumber===1)?(
      <>
       <div className='content'>
       <div class ="boardNumber"> <p class = "board-text">ROUND {roundNumber} [SCORE X 1]| BOARD {boardNumber} </p></div> 
      <div className='board'>
        <span className = 'card-purple chicken-image'></span>
        <span className = 'card-yellow chicken-image'></span>
        <span className = 'card-pink chicken-image'></span>
        </div>
        <div className='board' id="letter">
        <span className = 'card-word-purple'>
          <p className='score-purple'>{scoreletter1}</p>
          <p className='letter-purple'>{letter1}</p>
        </span>
        <span className = 'card-word-yellow'>
          <p className='score-yellow'>{scoreletter2}</p>
          <p className='letter-yellow'>{letter2}</p>
        </span>
        <span className = 'card-word-strongpink'>
          <p className='score-strongpink'>{scoreletter3}</p>
          <p className='letter-strongpink'>{letter3}</p>
        </span>
        </div>
        <div className='fields'>
          <p class ="medium-text">Enter a word containing these letters: </p>
          <input type='text'  onChange ={handleInputChange} value={inputValue} onKeyDown = {handleKeypressSubmit} autoFocus/>
        </div>
        <div className="buttons">
        <button type='button' className ="btn-purple" onClick = {()=> {handleSubmit()}}>
         Submit </button>
          <button type = 'button' className ='btn-grey' onClick={handleStartBoard}>Skip</button>
        </div>
        <div className = "modals">
        <HowtoPlay title="New Game" className = "HowtoPlay" onKeyUp= {keyPressClose}
        onClose ={() => {setHowtoPlay(false); setRound(true)}} show = {howtoplay} btntext="Start Game" btnclass= "btn-purple">
         <div>
          <h4 class ="play-headings">Basic Idea:</h4>
          <p class = "play-para">A board of letters will be given on the screen. Type a word that contains all the letters on the board and score points. For example:
              “O” “I” and “C” appears on the board. </p>
              <ul class = "play-list">
                <li>“VOICE” and “COINAGE” will be accepted because they use all the letters on the board. </li>
                <li>“FOLIAGE” or “COLLATERAL” will not be accepted as they are missing letters. </li>
                <li>"?" are wild cards — use it as any letter you'd like!  </li>
              </ul>
              </div>
         <div>
          <h4 class ="play-headings">Scoring:</h4>
          <p>Each letter will have a score on it. Every time you form a valid word, you will be given points as shown on the letters.</p>
         </div>
         <div>
          <h4 class ="play-headings">Rounds</h4>
          <p>There will be 3 rounds for each game.</p>
          <ul class = "play-list">
               <li>Round 1 - 7 boards - Score will be multiplied by 1 </li>
                <li>Round 2 - 6 board - Score will be multiplied by 2 </li>
                <li>Round 3 - 5 boards - Score will be multiplied by 3 </li>
          </ul>
          </div>
            </HowtoPlay>
        <Modal title="Success" className = "Success" onClose ={() => {setShow(false); handleStartBoard()}} show = {show} btntext="Next Round" btnclass= "btn-grey">
            <p>Score gain: {boardscore}</p>
            <p>Total score: {gamescore}</p>
            </Modal>
            <Modal title="Wrong Answer!" className="WrongAns" onClose = {()=> {handleGuessAgain()}} show= {wrongans} btntext="Try Again"  btnclass= "btn-grey">
            <p>Please check that your answer exists in the dictionary.</p>
            </Modal>
            <Round title= "Round 1" className="Round" onClose = {()=> {setRound(false)}} show= {round} btntext="Good Luck!"  btnclass= "btn-purple">
            <p> There are 3 letter boards shown.</p>
            <p> The total score of all letters shown will be multiplied by 1.</p>
            </Round>
            <GameOver title = {"Total Game Score: "+ gamescore} className="gameOver"  onClose ={() => getLeaderboard()}   show= {gameOver} btntext="Submit to Leaderboard" btnclass="btn-purple">
            <form class = "gameOver-form" onSubmit={e => { e.preventDefault(); }} >
              <p class = "medium-text"> Enter your name to submit your entry to the leaderboard: </p>
              <input className = "gameOver-input"type = "text" value = {playername} onChange={handleplayername} id="entername" maxLength = "16" autoFocus />
            </form>
            </GameOver> 
             <Leaderboard title = {"LEADERBOARD "} className="leaderboard" onClose ={() => {setLeaderboardModal(false); handleStartGame()} } show= {leaderboardModal} btnclass = "btn-purple" btntext="Next Game"  >
           <div class ="leaderboard-container">
                <div class="current_rank">You are currently ranked {playerrank || ""}</div>
                <div class ="rank" id="rank1">
                  <div class="trophy" id="trophy1"></div>
                    <div class="silhouette"></div>
                      <div class = "player_details">
                       <p>{FirstPlayerName}</p>
                      <div></div>
                       <p>{ FirstPlayerRecord}</p>
                      </div>
                      </div>
                <div class = "rank" id="rank2"> 
                <div class="trophy" id="trophy2"></div>
                <div class="silhouette"></div>
                   <div class = "player_details">
                    <p>{SecondPlayerName}</p> 
                    <div></div>
                    <p>{SecondPlayerRecord}</p></div>
                  </div>
                <div class="rank" id="rank3">
                  <div class="trophy" id="trophy3"></div>
                    <div class="silhouette"></div>
                    <div class = "player_details">
                      <p>{ThirdPlayerName} </p>
                      <div></div>
                      <p>{ThirdPlayerRecord}</p></div>
                      </div>
                <div class="rank" id="rank4"> 
                  <div class="trophy" id="trophy4"></div>
                    <div class="silhouette"></div>
                    <div class = "player_details"> 
                      <p>{FourthPlayerName}</p>
                      <div></div>
                      <p>{FourthPlayerRecord}</p>
                      </div>
                      </div>
           </div>
            </Leaderboard> 
        </div>
        </div>
        </>
          ) 
          : 
          isPlayOn && (roundNumber===2)?(
            <>
             <div className='content'>
             <div class ="boardNumber"> <p class = "board-text">ROUND {roundNumber} : SCORE X 2 | BOARD {boardNumber} </p></div> 
            <div className='board'>
              <span className = 'card-purple chicken-image'></span>
              <span className = 'card-yellow chicken-image'></span>
              <span className = 'card-pink chicken-image'></span>
              <span className = 'card-green chicken-image'></span>
              </div>
              <div className='board' id="letter">
              <span className = 'card-word-purple'>
                <p className='score-purple'>{scoreletter1}</p>
                <p className='letter-purple'>{letter1}</p>
              </span>
              <span className = 'card-word-yellow'>
                <p className='score-yellow'>{scoreletter2}</p>
                <p className='letter-yellow'>{letter2}</p>
              </span>
              <span className = 'card-word-strongpink'>
                <p className='score-strongpink'>{scoreletter3}</p>
                <p className='letter-strongpink'>{letter3}</p>
              </span>
              <span className = 'card-word-green'>
                <p className='score-green'>{scoreletter4}</p>
                <p className='letter-green'>{letter4}</p>
              </span>
              </div>
              <div className='fields'>
                <p class ="medium-text">Enter a word containing these letters: </p>
                <input type='text' onChange ={handleInputChange} value={inputValue} onKeyDown = {handleKeypressSubmit} autoFocus />
              </div>
      
              <div className="buttons">
              <button type='button' className ="btn-purple" onClick = {()=> {handleSubmit()}}>
               Submit </button>
      
                <button type = 'button' className ='btn-grey' onClick={handleStartBoard}>Skip</button>
              </div>
              <div className = "modals">
              <Modal title="Success" className = "Success" onClose ={() => {setShow(false); handleStartBoard();}}   show = {show} btntext="Next Round" btnclass= "btn-grey">
                  <p>Score gain: {boardscore}</p>
                  <p>Total score: {gamescore}</p>
                  </Modal>
                  <Modal title="Wrong Answer!" className="WrongAns" onClose = {()=> {handleGuessAgain()}} show= {wrongans} btntext="Try Again"  btnclass= "btn-grey">
                  <p>Please check that your answer exists in the dictionary.</p>
                  </Modal>
                  <Round title= "Round 2" className="Round" onClose = {()=> {setRound(false)}} show= {round} btntext="Good Luck!"  btnclass= "btn-purple">
                  <p> There are now 4 letter boards.</p>
                  <p> MEDIUM DIFFICULTY BONUS: The total score of all letters shown will be multiplied by 2.</p>
            </Round>
                  <GameOver title = {"Total Game Score: "+ gamescore} className="gameOver"  onClose ={() => getLeaderboard() }   show= {gameOver} btntext="Submit to Leaderboard" btnclass="btn-purple">
                  <form class = "gameOver-form" onSubmit={e => { e.preventDefault(); }} >
                    <p class = "medium-text"> Enter your name to submit your entry to the leaderboard: </p>
                    <input className = "gameOver-input"type = "text" value = {playername} onChange={handleplayername} onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setGameOver(false)
                      }
                    }} id="entername" maxLength = "16" autoFocus />
                  </form>
                  </GameOver> 
      
                   <Leaderboard title = {"LEADERBOARD "} className="leaderboard" onClose ={() => {setLeaderboardModal(false); handleStartGame()} } show= {leaderboardModal} btnclass = "btn-purple" btntext="Next Game"  >
                 <div class ="leaderboard-container">
                      <div class="current_rank">You are currently ranked {playerrank || ""}</div>
                      <div class ="rank" id="rank1">
                        <div class="trophy" id="trophy1"></div>
                          <div class="silhouette"></div>
                            <div class = "player_details">
                             <p>{FirstPlayerName}</p>
                            <div></div>
                             <p>{ FirstPlayerRecord}</p>
                            </div>
                            </div>
                      <div class = "rank" id="rank2"> 
                      <div class="trophy" id="trophy2"></div>
                      <div class="silhouette"></div>
                         <div class = "player_details">
                          <p>{SecondPlayerName}</p> 
                          <div></div>
                          <p>{SecondPlayerRecord}</p></div>
                        </div>
                      <div class="rank" id="rank3">
                        <div class="trophy" id="trophy3"></div>
                          <div class="silhouette"></div>
                          <div class = "player_details">
                            <p>{ThirdPlayerName} </p>
                            <div></div>
                            <p>{ThirdPlayerRecord}</p></div>
                            </div>
                      <div class="rank" id="rank4"> 
                        <div class="trophy" id="trophy4"></div>
                          <div class="silhouette"></div>
                          <div class = "player_details"> 
                            <p>{FourthPlayerName}</p>
                            <div></div>
                            <p>{FourthPlayerRecord}</p>
                            </div>
                            </div>
                 </div>
                  </Leaderboard> 
              </div>
              </div>
              </>
                ) 
                :
                isPlayOn && (roundNumber===3)?(
                  <>
                   <div className='content'>
                   <div class ="boardNumber"> <p class = "board-text">ROUND {roundNumber}  : SCORE X 3 | BOARD {boardNumber} </p></div> 
                  <div className='board'>
                    <span className = 'card-purple chicken-image'></span>
                    <span className = 'card-yellow chicken-image'></span>
                    <span className = 'card-pink chicken-image'></span>
                    <span className = 'card-green chicken-image'></span>
                    <span className = 'card-blue chicken-image'></span>
                    </div>
                    <div className='board' id="letter">
                    <span className = 'card-word-purple'>
                      <p className='score-purple'>{scoreletter1}</p>
                      <p className='letter-purple'>{letter1}</p>
                    </span>
                    <span className = 'card-word-yellow'>
                      <p className='score-yellow'>{scoreletter2}</p>
                      <p className='letter-yellow'>{letter2}</p>
                    </span>
                    <span className = 'card-word-strongpink'>
                      <p className='score-strongpink'>{scoreletter3}</p>
                      <p className='letter-strongpink'>{letter3}</p>
                    </span>
                    <span className = 'card-word-green'>
                      <p className='score-green'>{scoreletter4}</p>
                      <p className='letter-green'>{letter4}</p>
                    </span>
                    <span className = 'card-word-blue'>
                      <p className='score-blue'>{scoreletter5}</p>
                      <p className='letter-blue'>{letter5}</p>
                    </span>
                    </div>
                    <div className='fields'>
                      <p class ="medium-text">Enter a word containing these letters: </p>
                      <input type='text' onChange ={handleInputChange} value={inputValue} onKeyDown = {handleKeypressSubmit}  autoFocus />
                    </div>
            
                    <div className="buttons">
                    <button type='button' className ="btn-purple" onClick = {()=> {handleSubmit()}}>
                     Submit </button>
            
                      <button type = 'button' className ='btn-grey' onClick={handleStartBoard}>Skip</button>
                    </div>
                    <div className = "modals">
                    <Modal title="Success" className = "Success" onClose ={() => {setShow(false); handleStartBoard();}} show = {show} btntext="Next Round" btnclass= "btn-grey">
                        <p>Score gain (X3): {boardscore}</p>
                        <p>Total score: {gamescore}</p>
                        </Modal>
                        <Modal title="Wrong Answer!" className="WrongAns" onClose = {()=> {handleGuessAgain()}} show= {wrongans} btntext="Try Again"  btnclass= "btn-grey">
                        <p>Please check that your answer exists in the dictionary.</p>
                        </Modal>
                        <Round title= "Round 3" className="Round" onClose = {()=> {setRound(false)}} show= {round} btntext="Good Luck!"  btnclass= "btn-purple">
                        <p> There are now 5 letter boards.</p>
                        <p> BONUS: The total score of all letters shown will be multiplied by 3.</p>
            </Round>
                        <GameOver title = {"Total Game Score: "+ gamescore} className="gameOver"  onClose ={() => getLeaderboard() }   show= {gameOver} btntext="Submit to Leaderboard" btnclass="btn-purple">
                        <form class = "gameOver-form" onSubmit={e => { e.preventDefault(); }} >
                          <p class = "medium-text"> Enter your name to submit your entry to the leaderboard: </p>
                          <input className = "gameOver-input"type = "text" value = {playername} onChange={handleplayername} id="entername" maxLength = "16" autoFocus />
                        </form>
                        </GameOver> 
                         <Leaderboard title = {"LEADERBOARD "} className="leaderboard" onClose ={() => {setLeaderboardModal(false); setIsPlayOn(false)} } show= {leaderboardModal} btnclass = "btn-purple" btntext="New Game"  >
                       <div class ="leaderboard-container">
                            <div class="current_rank">You are currently ranked {playerrank || ""}</div>
                            <div class ="rank" id="rank1">
                              <div class="trophy" id="trophy1"></div>
                                <div class="silhouette"></div>
                                  <div class = "player_details">
                                   <p>{FirstPlayerName}</p>
                                  <div></div>
                                   <p>{ FirstPlayerRecord}</p>
                                  </div>
                                  </div>
                            <div class = "rank" id="rank2"> 
                            <div class="trophy" id="trophy2"></div>
                            <div class="silhouette"></div>
                               <div class = "player_details">
                                <p>{SecondPlayerName}</p> 
                                <div></div>
                                <p>{SecondPlayerRecord}</p></div>
                              </div>
                            <div class="rank" id="rank3">
                              <div class="trophy" id="trophy3"></div>
                                <div class="silhouette"></div>
                                <div class = "player_details">
                                  <p>{ThirdPlayerName} </p>
                                  <div></div>
                                  <p>{ThirdPlayerRecord}</p></div>
                                  </div>
                            <div class="rank" id="rank4"> 
                              <div class="trophy" id="trophy4"></div>
                                <div class="silhouette"></div>
                                <div class = "player_details"> 
                                  <p>{FourthPlayerName}</p>
                                  <div></div>
                                  <p>{FourthPlayerRecord}</p>
                                  </div>
                                  </div>
                       </div>
                        </Leaderboard> 
                    </div>
                    </div>
                    </>
                      )
                :
          (
            <div className="startgame">
           <div className = "chickenbackground">
           </div>
           <div className ="startheading">
            <header className="headerjamble">JAMBLE</header>
            <hr className ="blurline"></hr>
            <button className = 'btn-purple' id="startgamebutton"type='button' onClick={handleStartGame} >Start Game</button> 
        </div>
        </div>
          )}
        </div>
  )
    
        }

  
export default Jamble;