
import "./App.css";
import Jamble from "./components/jamble";


function App() {
  return (
    <div className="App">
  <div>  
   <nav>
  <h1>JAMBLE</h1>
  <ul>
    <li><a href="mailto:ruilingchin@gmail.com">Contact</a></li>
  </ul>
  </nav>
  </div>
      <main>
        <Jamble />
      </main>
    </div>
  );
}

export default App;