import React, {useEffect} from 'react';
import "./Modal.css"
import closemodal from"./closemodal.svg"

const Modal = props => {

    // const closeOnEscapeKeyDown = (e) => {
    //     if (!props.show){
    //         return null;
    //     }
    //     if((e.charCode===27 || e.keyCode===27) ||(e.charCode===13 || e.keyCode ===13)){
    //             props.onClose();
    //             console.log('escape keydown 2')
    //         }
    //     }
    // useEffect(()=>{
    //     document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    //     return function cleanup(){
    //         document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    //     }
    // }, [props.show])

    // console.log('modal props close is', props.show, props.className)

    const closeModal=(event)=> {
        if (event.keyCode === 13 || 27) {
            console.log('keypressevent')
            props.onClose();
        }
    }
    if (!props.show){
        return null
    }
    return (
        <div className ={props.className}  onKeyDown={e=>closeModal(e)}>
            <div className ="modal-content" id={props.modalid} onClick={e => e.stopPropagation()}>
               <div className="closemodal" onClick = {() => {props.onClose()}}> 
               <img className="closemodal-img"src={closemodal}></img>
               </div>
                <div className = "modal-header">
                    <h4 className = "modal-title">
                        {props.title}
                    </h4>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
                <div className="modal-footer">
                    <button autoFocus onClick = {() => {props.onClose()}} className= {props.btnclass} > {props.btntext}</button>
                </div>
            </div>
        </div>
    )
}

export default Modal