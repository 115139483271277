import React, {useEffect} from 'react';
import "./Round.css";
import closemodal from"./closemodal.svg";

const Round = props => {
    const closeOnEscapeKeyDown = (e) => {
        if (!props.show){
            return null;
        }//not firing
        if(e.charCode===13 ){
            console.log('rounding')
                props.onClose();
               
            }
        }
    useEffect(()=>{
        document.body.addEventListener('keydown', closeOnEscapeKeyDown);
        return function cleanup(){
            document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
           
        }
    }, [props.show])

    if (!props.show){
        return null
    }
    
    return (
        <div className ={props.className}  onClick ={props.onClose}>
            <div className ="round-content" id={props.modalid} onClick={e => e.stopPropagation()}>
            <div className="closemodal-round" onClick = {() => {props.onClose()}}> 
               <img className="closemodal-round-img"src={closemodal}></img>
               </div>
                <div className = "round-header">
                    <h4 className = "round-title">
                        {props.title}
                    </h4>
                </div>
                <div className="round-body">
                    {props.children}
                </div>
                <div className="round-footer">
                    <button autoFocus onClick = {() => {props.onClose()}} className= {props.btnclass} > {props.btntext}</button>
                </div>
            </div>
        </div>
    )
}

export default Round